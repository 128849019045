import React, { useEffect, useState } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { useContract, useContractRead, useSwitchChain, useChain, useChainId } from "@thirdweb-dev/react";
import { formatEther } from 'viem';

import { useDispatch, useSelector } from 'react-redux';
import { setTokenPurchased } from '../redux/actions';

const Hero = ({connected}) => {

    const switchChain = useSwitchChain();

    const chain = useChain();

    const chainId = useChainId();
    
    const [ connectedChain, setConnectedChain ] = useState(null)

    // connected = "0x17F2bEF74EeCd7dbb4a7A33d7dB84249164Ca2a8";

    const [ salesPercent, setSalesPercent ] = useState(0);
    const [ globalStats, setGlobalStats ] = useState({sold: 0});
    const [ userDash, setUserDash ] = useState({purchased: 0});
    
    const [ iclicSales, setIclicSales ] = useState({});

    const { contract: iclickSalesContract } = useContract(
        iclicSales.address,
        iclicSales.abi,
    );

    const { data: usersData, isLoading: loadingUser } = useContractRead(iclickSalesContract, "users", [ connected ]);

    const { data: globalData, isLoading: loadingGlobal } = useContractRead(iclickSalesContract, "systemlog");


    // change network
    const changeNetwork = async () => {
        try{
            await switchChain(1);
        }catch (e) {}
    }

    useEffect(() => {
        if(chain) {
            setConnectedChain({
                name: chainId === 1 ? chain.name : 'Wrong Network!',
                id: chainId
            })
        };
    }, [chain, chainId])

    useEffect(() => {
        if(globalData){
            let sold = parseFloat(formatEther(globalData.totalTokenSold));
            let toRaise = 30_000_000_000;
            let percentage = sold / toRaise;
            setGlobalStats({
                sold: sold
            })
            setSalesPercent(parseFloat(percentage * 100).toFixed(2))
        }
    }, [globalData]);

    useEffect(() => {
        if(usersData){
            setUserDash({
                purchased: parseFloat(formatEther(usersData._total))
            });
        }
    }, [usersData])

    useEffect(() => {
        fetch('IclickSales.json').then(response => {
            response.json().then(contract => {
                setIclicSales(contract);
            });
        });
    }, []);

    const dispatch = useDispatch();
    const tokenPurchased = useSelector((state) => state.token.tokenPurchased);

    useEffect(() => {
        if (tokenPurchased === null && usersData) {
            dispatch(setTokenPurchased(parseFloat(formatEther(usersData._total)))); 
        }
    }, [tokenPurchased, usersData, dispatch]);

    const renderer = ({ days, hours, minutes, seconds }) => {
        return(<span>
            {zeroPad(days)}d  {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s
        </span>)
    };

    const phase1 = 1696258800000 + 24 * 60 * 60 * 1000 * 7;
    const phase2 = 1696258800000 + 24 * 60 * 60 * 1000 * 15;
    const phase3 = 1696258800000 + 24 * 60 * 60 * 1000 * 22;
    const phase4 = 1696258800000 + 24 * 60 * 60 * 1000 * 30;

    return(<>
        <section className="py-12 lg:py-24">
            <div className="container flex flex-col items-center gap-16 px-4 lg:flex-row lg:gap-4 lg:px-0">
                <div className="w-full lg:w-1/2" style={{opacity: 1, transform: 'none'}}>
                    <h2 className="mb-6 text-center text-3xl leading-normal lg:text-left lg:text-5xl">
                        Take Part of 
                        <span className="mx-3 bg-gradient-to-b from-[#F320D8] to-[#4B0491] bg-clip-text text-transparent">
                            ICLICK Inu ERC20 Public Sale!</span>  🚀
                    </h2>
                    <p className="mb-2 text-center font-normal leading-relaxed text-white/80 lg:text-left lg:text-xl">
                        For the next 31 days, seize the opportunity to acquire ICLICK Inu tokens using BSC BEP20 
                        to minimize fees. After the sale, easily claim your tokens into your ERC20 address 
                        at a fraction of the cost compared to direct ETH purchases. Don't miss out on 
                        this cost-effective chance to join the ICLICK Inu ecosystem. Buy now and empower 
                        your crypto portfolio! 💰🌐 #ICLICKInu #CryptoSale
                    </p>
                    <p className="mb-8 text-center font-normal leading-relaxed text-white/80 lg:text-left lg:text-xl">
                        <div className="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-start">
                            <a href="https://iclickinu.com/whitepaper.pdf" target="_blank" 
                                className="group flex items-center gap-1 rounded-lg bg-gradient-to-tr 
                                from-[#16A6EE] to-[#F320D8] py-4 px-6 text-sm font-semibold transition-opacity 
                                duration-200 hover:opacity-75 lg:text-base" rel="noreferrer">
                                <span>Whitepaper</span>
                            </a>
                            <a href="https://github.com/TechRate/Smart-Contract-Audits/blob/main/July_2023/ICLICK%20INU.pdf"
                                rel="noreferrer" className="group flex items-center gap-1 rounded-lg bg-gradient-to-tr from-[#16A6EE] to-[#F320D8] py-4 px-6 text-sm font-semibold transition-opacity duration-200 hover:opacity-75 lg:text-base" target="_blank">
                                <span>Audit</span>
                            </a>
                            <a href="https://gopluslabs.io/token-security/1/0xC819617f360f6347D222f260E9F4987cA1d0f879"
                                rel="noreferrer" className="group flex items-center gap-1 rounded-lg bg-gradient-to-tr from-[#16A6EE] to-[#F320D8] py-4 px-6 text-sm font-semibold transition-opacity duration-200 hover:opacity-75 lg:text-base" target="_blank">
                                <span>Token Security</span>
                            </a>
                            <a href="https://etherscan.io/token/0xC819617f360f6347D222f260E9F4987cA1d0f879"
                                rel="noreferrer" className="group flex items-center gap-1 rounded-lg bg-gradient-to-tr from-[#16A6EE] to-[#F320D8] py-4 px-6 text-sm font-semibold transition-opacity duration-200 hover:opacity-75 lg:text-base" target="_blank">
                                <span>Contract Address</span>
                            </a>
                        </div>
                    </p>
                    
                    <p className="mb-2 text-center font-normal leading-relaxed text-white/80 lg:text-left lg:text-xl">
                        <div>
                        🚀 ICLICK Inu ERC20 Public Sale with Exciting Bonuses! 🚀
                        <div className='mt-3 h-px bg-gradient-to-r from-white/0 to-white/20'></div>
                        🌟 Day 1 to Day 7: +15% - Ends in  <Countdown date={phase1} renderer={renderer} />
                        <div className='mt-2 h-px bg-gradient-to-r from-white/0 to-white/20'></div>
                        🌟 Day 8 to Day 15: +10% - Ends in  <Countdown date={phase2} renderer={renderer} />
                        <div className='mt-2 h-px bg-gradient-to-r from-white/0 to-white/20'></div>
                        🌟 Day 16 to Day 22: +5% - Ends in  <Countdown date={phase3} renderer={renderer} />
                        <div className='mt-2 h-px bg-gradient-to-r from-white/0 to-white/20'></div>
                        🌟 Day 23 to Day 30: +2% - Ends in  <Countdown date={phase4} renderer={renderer} />
                        <div className='mt-2 h-px bg-gradient-to-r from-white/0 to-white/20'></div>
                        </div>
                    </p>
                    {/* <div className="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-start">
                        <br />
                        Take advantage of our unique opportunity and earn from this public sales, Earn 5% instantly for every referrer when they use your affiliate link.
                    
                    </div> */}
                </div>
                <div className="relative flex w-full justify-center lg:w-1/2" style={{opacity: 1, transform: 'none',}}>
                    <div className="relative mx-auto w-full max-w-lg overflow-hidden rounded-3xl bg-[#1B0432]/80 backdrop-blur-xl">
                        {( loadingUser || loadingGlobal ) && <div className="absolute inset-0 z-20 flex h-full w-full 
                            items-center justify-center bg-black/60 backdrop-blur-sm" 
                                role="status">
                            <svg aria-hidden="true" class="h-8 w-8 animate-spin fill-primary text-gray-200" 
                                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div>}
                        <div className="mb-6 flex flex-col gap-6 px-5 pt-8">
                            <h4 className="bg-gradient-to-b from-[#F320D8] to-[#16A6EE] 
                                bg-clip-text text-center text-3xl font-medium 
                                text-transparent lg:text-4xl">PUBLICSALE IS OVER</h4>
                            {/* <div className="text-center">
                                Get +{phasePercent}% Today<br /> Ends in: <Countdown date={phase} renderer={renderer} />
                            </div> */}
                            <div className="relative h-7 overflow-hidden rounded-xl bg-white">
                                <div className="absolute z-10 flex h-full w-full items-center justify-center text-md text-black">
                                    <p><strong>{salesPercent}% Sold!</strong></p>
                                </div>
                                <div className="absolute inset-0 z-0 bg-gradient-to-r from-[#16A6EE] to-[#F320D8]" style={{width: `${salesPercent}%`}}></div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex items-end justify-center gap-2">
                                    <b>TOKENS SOLD</b>
                                    <span className="text-xl">
                                        <b>{globalStats.sold.toFixed(5)} ICLICK</b>
                                    </span>
                                </div>
                            </div>
                            {connectedChain && <div className="flex flex-col items-center">
                                <button 
                                    className="flex gap-2 rounded-lg border 
                                    border-white/10 bg-transparent py-2 px-4 
                                    text-center font-semibold lg:py-4"
                                    onClick={async () => {
                                        await changeNetwork();
                                    }}
                                    >
                                    {chainId === 1 && 
                                    // <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6">
                                    //     <g clip-path="url(#clip0_7_306)">
                                    //         <path d="M8.56214 11.7658L13.9999 6.32797L19.4403 11.7681L22.6043 8.60414L13.9999 0L5.39838 8.60156L8.56225 11.7656M0 13.9999L3.1641 10.8354L6.32797 13.9993L3.16387 17.1634L0 13.9999ZM8.56214 16.2344L13.9999 21.6719L19.4402 16.2319L22.6059 19.3942L22.6043 19.3959L13.9999 27.9999L5.39838 19.3983L5.3939 19.3938L8.56248 16.2341M21.6719 14.0013L24.836 10.8372L27.9999 14.0011L24.8359 17.1652L21.6719 14.0013Z" fill="#F3BA2F"></path>
                                    //         <path d="M17.2089 13.9983H17.2102L13.9999 10.7877L11.6269 13.16L11.3543 13.4327L10.7921 13.995L10.7876 13.9994L10.7921 14.004L13.9999 17.2122L17.2104 14.0016L17.212 13.9998L17.2091 13.9983" fill="#F3BA2F"></path>
                                    //     </g>
                                    //     <defs>
                                    //         <clippath id="clip0_7_306">
                                    //             <rect width="28" height="27.9999" fill="white"></rect>
                                    //         </clippath>
                                    //     </defs>
                                    // </svg>
                                    "ETH - "
                                    }
                                    <span className="truncate">{ connectedChain.name }</span>
                                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" 
                                        className="h-3 w-3" style={{position: "relative", top: "5px"}}>
                                        <path d="M1 1L6 6L11 1" stroke="currentColor" strokeWidth="2" stroke-linejoin="round"></path>
                                    </svg>
                                </button>
                            </div>}
                            <div className="rounded-l bg-gradient-to-br from-[#16A6EE] to-[#F320D8] py-1.5 px-1.5 text-center lg:text-l">
                                <p className="mb-0">Your Purchased = {Number(userDash.purchased).toFixed(6)} ICLICK</p>
                            </div>
                            {/* <div className="flex gap-4 rounded-xl border-2 border-transparent bg-[#232C3A]/50 py-2.5 pl-2.5 pr-6 ring-4 ring-transparent focus-within:border-primary/50 focus-within:ring-primary/20 ">
                                <button type="button" className="flex w-32 items-center gap-2 rounded-xl bg-[#4B0491] py-2.5 px-4 
                                    transition-opacity duration-200 hover:opacity-75" onClick={() => { setModalShow(true) }}>
                                    {payOption === 'bnb' ? <><img src={bnbLogo} alt="BNB" className="h-7 w-7 object-contain" /><span>BNB</span></> : 
                                    <><img src={usdtLogo} alt="BNB" className="h-7 w-7 object-contain" /><span>USDT</span></>}
                                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L6 6L11 1" stroke="currentColor" strokeWidth="2" strokeLinejoin="round"></path>
                                    </svg>
                                </button>
                                <div className="flex flex-1 flex-col gap-1">
                                    <label className="text-sm">{amountLabel} Amount</label>
                                    <input className="w-full bg-transparent text-xl outline-none" 
                                        type="number" min="0" step="any" 
                                        placeholder="0" 
                                        value={amountToBuy}
                                        onChange={e => { setAmountToBuy(e.target.value); setTokenEntered(false)} } />
                                </div>
                            </div> 
                            <div className="py-0 px-0 text-center lg:text-l">
                                <span className="text-white/90"><b>~ 1 ICLICK = ${ tokenPrice }</b></span>
                            </div>
                            <div className="flex gap-4 rounded-xl border-2 border-transparent bg-[#232C3A]/50 py-2.5 pl-2.5 pr-6 ring-4 ring-transparent focus-within:border-primary/50 focus-within:ring-primary/20"><button type="button" className="flex w-32 items-center gap-2 rounded-xl bg-[#4B0491] py-2.5 px-4 transition-opacity duration-200 hover:opacity-75" disabled="">
                                <img src={ iclickIcon } alt="ICLICK" className="h-7 w-7 object-contain" />
                                    <span>ICLICK</span>
                                    </button>
                                <div className="flex flex-1 flex-col gap-1">
                                    <label className="text-sm">ICLICK Amount</label>
                                    <input className="w-full bg-transparent text-xl outline-none" 
                                        type="number" min="0" step="any" 
                                        placeholder="0.0"
                                        value={ iclickToBuy } 
                                        onChange={e => { setIclicToBuy(e.target.value); setTokenEntered(true) }} />
                                </div>
                            </div>
                            <div className="-mx-5 h-[2px] bg-[#30353A]"></div>
                            {payOption === 'bnb' && 
                            <button className="text-normal 
                                relative flex w-full items-center 
                                justify-center gap-2 rounded-xl 
                                bg-gradient-to-tr from-[#16A6EE] 
                                to-[#F320D8] py-4 px-6 font-semibold transition-opacity duration-200 
                                hover:opacity-75 disabled:cursor-not-allowed disabled:opacity-80 lg:text-xl" 
                                type="button" onClick={async() => { await buyWithBNB() }}>
                                {!connected ? "Connect Wallet" :  "PURCHASE ICLICK ERC20" }
                            </button>}
                            {payOption !== 'bnb' && <button className="text-normal 
                                    relative flex w-full items-center 
                                    justify-center gap-2 rounded-xl 
                                    bg-gradient-to-tr from-[#16A6EE] 
                                    to-[#F320D8] py-4 px-6 font-semibold transition-opacity duration-200 
                                    hover:opacity-75 disabled:cursor-not-allowed disabled:opacity-80 lg:text-xl" 
                                    type="button" onClick={async() => { Number(appvdAmount) >= Number(amountToBuy) ? await buyWithUSDT() : await apprvdUSDT() }}>
                                    {!connected ? "Connect Wallet" : `${ Number(appvdAmount) >= Number(amountToBuy) ? "PURCHASE ICLICK ERC20" : "APPROVE USDT" }`}
                                </button>} */}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <Payoption  
            modalShow={modalShow} 
            setModalShow={setModalShow} 
            payOption={payOption}
            setPayOption={setPayOption}/> */}
    </>)
}

export default Hero;