const Footer = () => {
    return(
        <footer className="bg-[#14181C]/60 backdrop-blur-xl">
            <div className="h-px bg-gradient-to-r from-white/20 to-white/0"></div>
            <div className="container px-4 pt-12 lg:px-0">
                {/* <h4 className="mb-6 text-center text-3xl font-semibold lg:mb-12 lg:text-5xl">Social Media</h4>
                <div className="mb-6 flex items-center justify-center gap-4 lg:mb-12 lg:gap-12"></div> */}
                {/* <div className="h-0.5 bg-white/10"></div> */}
                <div className="text-center">
                    <p className="text-center">CopyRight © 2023 <span className="font-semibold">ICLCIK INU</span> All Rights Reserved</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;