import React from 'react';
import { useSelector } from 'react-redux';

const Claim = () => {
    const tokenPurchased = useSelector((state) => state.token.tokenPurchased);

    return(<>
        <section className="py-0 lg:py-0">
            <div className="container flex flex-col items-center gap-16 lg:flex-row lg:gap-0 lg:px-0">
                <div className="w-full lg:w-1/2"></div>
                <div className="w-full lg:w-1/2" style={{opacity: 1, transform: 'none'}}>
                <div className="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-start">
                    
                    You have have purchased { tokenPurchased } ICLICK.
                    <br />
                    <span className="group flex items-center gap-1 rounded-lg bg-gradient-to-tr from-[#16A6EE] to-[#F320D8] py-4 px-6 text-sm font-semibold transition-opacity duration-200 hover:opacity-75 lg:text-base" > 
                    CLICK HERE TO CLAIM YOUR PURCHASE </span>
                </div>
                </div>
            </div>
        </section>
    </>)
}

export default Claim;