import { SET_TOKEN_PURCHASED } from './actions';

const initialState = {
  tokenPurchased: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN_PURCHASED:
      return {
        ...state,
        tokenPurchased: state.tokenPurchased === null ? action.payload : state.tokenPurchased,
      };
    default:
      return state;
  }
};

export default reducer;
