import React, { useEffect, useState } from 'react';
// import { useAccount as getAccount } from 'wagmi';
import { CopyToClipboard } from "react-copy-to-clipboard";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const Howtobuy = ({connected}) => {
    const SweetA = withReactContent(Swal);
    const [ isCopied, setIsCopied ] = useState(false);
    const [ _affLink, setaffLink ] = useState();

    // const { address, isConnecting, isDisconnected } = getAccount()

    useEffect(() => {
        if(isCopied){
            SweetA.fire(<p>Affiliate Link Copied!</p>)
        }
        setIsCopied(false);
    }, [isCopied]);

    useEffect(() => {
        let root = window.location.href.split('?')[0];
        setaffLink(`${root}?ref=${connected}`)
    }, [connected]);

    return(
        <section id="how-to-buy" className="overflow-hidden py-12 lg:py-24">
            <div className="container gap-8 px-4 lg:px-0">
                <div className="mb-20 h-px bg-gradient-to-r from-white/0 to-white/20"></div>
                <div className="flex flex-col items-center gap-8 lg:flex-row lg:gap-16">
                    <div className="flex w-full flex-col gap-6 lg:w-1/2" style={{opacity: 1, transform: 'none'}}>
                        <div className="gradient-border flex w-full flex-col items-center gap-6 rounded-2xl bg-[#1A2025]/70 p-8 backdrop-blur-xl transition-all duration-200 hover:scale-105 lg:flex-row">
                            <div className="gradient-border flex h-16 w-16 items-center justify-center text-3xl font-bold before:rounded-full lg:h-20 lg:w-20">1.</div>
                            <div className="flex-1 text-center lg:text-left">
                                <h4 className="text-gradient mb-4 text-xl font-medium">Connect to wallet</h4>
                                <p className="text-white/80">Connect with your preferable crypto wallet, we offer options: TrustWallet, MetaMask, Wallet Connect with more than 170+ wallets available!</p>
                            </div>
                        </div>
                        <div className="gradient-border flex w-full flex-col items-center gap-6 rounded-2xl bg-[#1A2025]/70 p-8 backdrop-blur-xl transition-all duration-200 hover:scale-105 lg:flex-row">
                            <div className="gradient-border flex h-16 w-16 items-center justify-center text-3xl font-bold before:rounded-full lg:h-20 lg:w-20">2.</div>
                            <div className="flex-1 text-center lg:text-left">
                                <h4 className="text-gradient mb-4 text-xl font-medium">Choose payable token &amp; chain</h4>
                                <p className="text-white/80">You can participate on ICLICK public-sale only from BSC Network [BEP20] by using BNB or USDT, this is to 
                                    reduce the gas fees you could be charged from ETH network and ease the process!</p>
                            </div>
                        </div>
                        <div className="gradient-border flex w-full flex-col items-center gap-6 rounded-2xl bg-[#1A2025]/70 p-8 backdrop-blur-xl transition-all duration-200 hover:scale-105 lg:flex-row">
                            <div className="gradient-border flex h-16 w-16 items-center justify-center text-3xl font-bold before:rounded-full lg:h-20 lg:w-20">3.</div>
                            <div className="flex-1 text-center lg:text-left">
                                <h4 className="text-gradient mb-4 text-xl font-medium">Receive tokens</h4>
                                <p className="text-white/80">Your ICLICK tokens are immediately after your purchase has received at least 5 blocks confirmations, 
                                    however you will be called to claim your token at end of the sales campaign [Claiming starts on 18th October, 2023].</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2" style={{opacity: 1, transform: 'none'}}>
                        <h2 className="text-gradient mb-4 text-center text-5xl leading-normal lg:text-left">How to buy</h2>
                        <div className="mb-4 flex flex-col gap-4 text-center font-normal leading-relaxed text-white/80 lg:mb-8 lg:text-left lg:text-lg">
                            <p>Refer a friend and you will receive a 5% BNB or USDT [BEP20] from their purchase! In order to receive your bonus, You need to make a purchase of at least $100 and your referral as well needs to purchase tokens worth of at least $100.</p>
                            <p>Your bonus will be sent to you automatically after the pruchse has received at least 5 blocks confirmations, to your crypto wallet that is related with your referral link.</p>
                        </div>

                        <br />
                            Take advantage of our unique opportunity and earn from this public sales, Earn 5% instantly for every referrer when they use your affiliate link.
                        
                        <div className="mb-6 mt-3 flex flex-wrap items-center justify-center gap-4 lg:justify-start">
                            <CopyToClipboard text={_affLink} onCopy={() => setIsCopied(true)}>
                                <span className="group flex items-center gap-1 rounded-lg bg-gradient-to-tr 
                                from-[#16A6EE] to-[#F320D8] py-4 px-6 text-sm font-semibold 
                                transition-opacity duration-200 hover:opacity-75 lg:text-base" > COPY AFFLIATE LINK </span>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
                <div className="mt-20 h-px bg-gradient-to-r from-white/20 to-white/0"></div>
            </div>
        </section>
    );
}

export default Howtobuy;