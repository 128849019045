import React, { useEffect, useState } from 'react';
import { useAddress } from "@thirdweb-dev/react";
import { zeroAddress } from 'viem';
import Header from "./components/Header";
import Hero from './components/Hero';
const App = () => {
  const [ connected, setAddress ] = useState(zeroAddress);
  const address = useAddress();
  useEffect(() => {
      if(address) setAddress(address);
      if(!address) setAddress(zeroAddress);
  }, [address]);
  return(<>
      <Header />
      <Hero connected={connected} />
  </>)
}

export default App;