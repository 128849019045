import { ConnectWallet } from "@thirdweb-dev/react";
import logo from '../img/logo.png';

const Header = () => {
    return(
        <div className="container px-4 lg:px-0">
            <div className="flex items-center justify-between py-6">
                <a href="https://buy.iclickinu.com//">
                    <img src={ logo } alt="logo" className="h-6 lg:h-16" />
                </a>
                <div className="flex items-center lg:gap-6">
                    <nav className="hidden lg:block">
                        <ul className="flex gap-6">
                            <li><a href="https://iclickinu.com/" className="text-lg font-medium uppercase transition-opacity duration-200 hover:opacity-75">Home</a></li>
                            <li><a href="https://iclickinu.com/project" className="text-lg font-medium uppercase transition-opacity duration-200 hover:opacity-75">Project</a></li>
                            <li><a href="https://iclickinu.com/plans" className="text-lg font-medium uppercase transition-opacity duration-200 hover:opacity-75">Plans</a></li>
                            <li><a href="https://iclickinu.com/calculator" className="text-lg font-medium uppercase transition-opacity duration-200 hover:opacity-75">Calculator</a></li>
                        </ul>
                    </nav>
                    <div className="flex items-center gap-2">
                        <ConnectWallet/>
                        {/* <button className="flex items-center gap-9 rounded-lg bg-gradient-to-tr from-[#16A6EE] to-[#F320D8] py-2 px-8 text-sm font-normal text-white transition-opacity duration-200 hover:opacity-75 lg:py-4 lg:text-base">
                            <b><span>Dashboard</span></b></button> */}
                    </div>
                </div>
            </div>
            <div className="h-px bg-gradient-to-r from-black/0 to-white/20"></div>
        </div>
    );
}

export default Header;