import about from '../img/about-0597b00b.png'
import mission from '../img/mission-072a60a7.png'
import vision from '../img/feature2.png'

const About = () => {
    return(
        <section id="about" className="overflow-hidden py-12">
            <div className="container flex flex-col items-center gap-16 px-4 lg:px-0">
                <div className="flex flex-col items-center gap-16 lg:flex-row">
                    <div className="order-last w-full lg:order-first lg:w-1/2" style={{opacity: 1, transform: 'none'}}>
                        <img src={about} alt="about-img" className="h-auto max-w-full" />
                    </div>
                    <div className="w-full lg:w-1/2" style={{opacity: 1, transform: 'none'}}>
                        <h4 className="mb-6 bg-gradient-to-b from-[#F320D8] to-[#16A6EE] bg-clip-text text-3xl font-medium leading-snug text-transparent lg:text-5xl">INOVATIVE AI POWERED DEFI PLATFORM</h4>
                        <p className="mb-6">
                            Welcome to our innovative platform that revolutionizes the way you advertise 
                            and monetize your brand. Our platform is built on blockchain technology, 
                            ensuring security, transparency, and efficiency in every transaction. 
                        </p>
                        <p>With a user-friendly interface and powerful features, we provide you with the tools to reach your target audience effectively. Join our growing community of advertisers and publishers, and take your brand to new heights. Experience the future of advertising and monetization with us.</p>
                    </div>
                </div>
                <div className="flex flex-col items-center gap-16 lg:flex-row">
                    <div className="lg:w-1/2" style={{opacity: 1, transform: 'none'}}>
                        <h4 className="mb-6 bg-gradient-to-b from-[#F320D8] to-[#16A6EE] bg-clip-text text-3xl font-medium leading-snug text-transparent lg:text-5xl">MISSION</h4>
                        <p className="rounded-xl bg-gradient-to-tr from-[#16A6EE] to-[#F320D8] p-3">ICLICK INU's mission is to concile Traditional PTC to the DeFi industry, 
                        allowing advertisers get better results from their advertisement budget while providing income opportunities in a secured, reliable and transparent manner.</p>
                        <p className="p-3">Our ecosystem offers passive income through Crypto Powered PTC, Crypto Currency Litteracy, e-Commerce and SUSTAINABLE TOKENOMICS. </p>
                    </div>
                    <div className="w-full lg:w-1/2 " style={{opacity: 1, transform: 'none'}}>
                        <img src={mission} 
                        alt="about-img" className="h-auto max-w-full" />
                    </div>
                </div>
                <div className="flex flex-col items-center gap-16 lg:flex-row">
                    <div className="order-last w-full lg:order-first lg:w-1/2" style={{opacity: 1, transform: 'none'}}>
                        <img src={ vision }
                            alt="about-img" className="h-auto max-w-full" />
                    </div>
                    <div className="w-full lg:w-1/2" style={{opacity: 1, transform: 'none'}}>
                        <h4 className="mb-6 bg-gradient-to-b from-[#F320D8] to-[#16A6EE] bg-clip-text text-3xl font-medium leading-snug text-transparent lg:text-5xl">VISION</h4>
                        <p className="">Our vision at ICLICK is to be the driving force behind the Transformation of DeFi , providing advertisers with cutting-edge blockchain Technology for safter, trusted and Smarter Advertisement while providing
                        a value to advertisers putting them directly in front of potiential clients, and creating a dynamic ecosystem that offers limitless potential for growth and prosperity.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;