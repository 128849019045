import React from "react";
import { Provider } from 'react-redux';
import { createRoot } from "react-dom/client";
import App from "./App";
import About from "./components/About";
import Howtobuy from "./components/Howtobuy";
import Footer from './components/Footer';
import { ThirdwebProvider } from "@thirdweb-dev/react";
import store from './redux/store';
import "./styles/Main.css";
import Claim from "./Claim";

const activeChain = "binance";
const activeChain2 = "ethereum";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <ThirdwebProvider
      activeChain={activeChain}
      clientId={process.env.REACT_APP_TEMPLATE_CLIENT_ID}
    >
      <main id="main" className="flex flex-col">
        <App />
      </main>
    </ThirdwebProvider>
    <ThirdwebProvider
      activeChain={activeChain2}
      clientId={process.env.REACT_APP_TEMPLATE_CLIENT_ID}
    >
      <Claim />
    </ThirdwebProvider>
    <Howtobuy connected={""} />
    <About />
    <Footer />
  </Provider>
);
